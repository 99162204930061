import React from 'react'
import { FC } from 'react'
import tw from 'twin.macro'

export const Pill = tw.div`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800`
export const PositivePill = tw(Pill)`bg-green-100 text-green-800`
export const NegativePill = tw(Pill)`bg-red-100 text-red-800`
export const NeutralPill = tw(Pill)`bg-indigo-100 text-indigo-800`
export const OrangePill = tw(Pill)`bg-orange-100 text-orange-800`
export const YellowPill = tw(Pill)`bg-yellow-100 text-yellow-800`

export const WarnPill: FC<{ title: string }> = ({ children, title }) => (

    <div tw="p-2">
        <div tw="inline-flex items-center bg-white leading-none rounded-full p-2 shadow text-red-600 text-sm">
            <svg xmlns="http://www.w3.org/2000/svg" tw="h-5 w-5 text-red-500 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
            </svg>
            &nbsp;{children}
        </div>
    </div>
    // <div tw="p-4 sm:p-5 bg-blue-50 rounded-lg flex-grow" {...rest}>
    //   <div tw="flex flex-row">
    //     <svg xmlns="http://www.w3.org/2000/svg" tw="h-6 w-6 text-blue-400 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
    //       <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
    //     </svg>
    //     <div tw="ml-5 text-xs sm:text-base">
    //       <h1 tw="text-sm sm:text-lg leading-6 font-semibold text-blue-600">
    //         {title}
    //       </h1>
    //       {children}
    //     </div>
    //   </div>
    // </div>
  )
