import { Link, useNavigate } from '@reach/router'
import React, { useEffect, useState } from 'react'
import 'twin.macro'
import { CardContent, ErrorMessageText, FormButton, InfoMessage, SuccessMessage, SuccessMessageText, WarnMessage, WarnMessageText } from '../../components/elements'

import profile from './images/profile-1.jpg'

import { OnboardingStep, useMatchOnboardingState } from '../../content/onboarding/OnboardingStep'
import { NeutralPill, WarnPill } from '../../components/elements/Pill'
import { Spinner } from '../../components/elements/Loading'
import { ROUTES } from '../../components/layout/Navigation'

const specialisms = ['Airline/Travel Disputes', 'Consumer Rights', 'Litigation']

const OnboardingWrapper = () => (
  <OnboardingStep
    step={3}
    desktopTitle={'3. Meet your specialist solicitor'}
    imageUrl='https://images.unsplash.com/photo-1539627831859-a911cf04d3cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80'
  >
    <OnboardingStep3 />
  </OnboardingStep>
)

const OnboardingStep3 = () => {
  // const navigate = useNavigate()
  const { actions, state } = useMatchOnboardingState()
  const [hasMatch, setMatch] = useState(state.hasMatched)

  useEffect(() => {
    setTimeout(() => {
      setMatch(true)
      actions.update({ hasMatched: true })
    }, 3000 + (Math.random() * 3000))
  }, [])

  return hasMatch
    ? <MatchFound />
    : <Searching />
}

const Searching = () => (
  <div tw="p-8 w-full h-full flex flex-col items-center justify-center" style={{minHeight: '500px'}}>
    <p tw="text-center text-gray-600">
      <strong>Matching you with an expert solicitor from our network</strong>
    </p>
    <p tw="text-center text-gray-600 mt-2">
      This may take up to a minute...
    </p>

    <Spinner tw="mt-4" />
  </div>
)

const MatchFound = () => {
  const { actions } = useMatchOnboardingState()
  const navigate = useNavigate()

  const complete = () => {
    actions.update({ completed: true })
    navigate(ROUTES.onboarding.step3)
  }
  return (
    <CardContent>
      <SuccessMessage title="Success!">
        <SuccessMessageText>
          We have found a solicitor who is a specialist in this area.
        </SuccessMessageText>
      </SuccessMessage>

      <h2 tw="mt-4 text-lg font-bold leading-8">
        Your Solicitor
      </h2>

      <div tw="flex flex-row items-center justify-start my-4">
        <div tw="relative shadow h-24 w-24 border-white rounded-full overflow-hidden border-4 flex-shrink-0">
          <img tw="object-cover w-full h-full" src={profile} />
        </div>
        <div tw="ml-4">
          <h1 tw="text-lg font-semibold">
            Jas U.
          </h1>
          <p tw="text-sm text-gray-600">
            SRA Licensed Solicitor
          </p>
          <p tw="text-sm text-gray-600">
            11 years experience
          </p>
        </div>
      </div>


      <p tw="mt-3 text-sm text-gray-600 font-bold">
        Specialist in
      </p>

      <div tw="mt-2 flex flex-wrap">
        {specialisms.map((s, i) => <NeutralPill key={`sp-${i}`} tw="mr-2 mb-2 last:ml-0">{s}</NeutralPill>)}
      </div>

      <p tw="mt-3 text-sm text-gray-600 font-semibold">
        <WarnPill title="Jas Is Popular">
            3 slots remaining for this week
        </WarnPill>
      </p>

      <div tw="mt-6 sm:mt-8 mb-2">
        <FormButton
          onClick={complete}
          tw="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out disabled:text-gray-50 disabled:cursor-not-allowed disabled:bg-indigo-300"
        >
          Get legal help from Jas
        </FormButton>
      </div>

    </CardContent>
  )
}

export default OnboardingWrapper
